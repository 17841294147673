.App {
  text-align: center;
  height: 100%;
}

#root {
  height: 100%;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: clip;
  font-family: 'Ubuntu', sans-serif;
}